.article-meta {
  color: #666;
  font-size: 0.9rem;
}
.source-link {
  text-decoration: none;
  color: #dc3545;
  border: 1px solid #dc3545;
  padding: 6px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.article-content{
  font-size: 16px;
}
.card-title{
    font-size: 25px;
    line-height: 115%;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: #333;
}
.source-link:hover {
  background-color: #dc3545;
  color: white;
}
.arrow-icon {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .card-title{font-size: 20px;}
  .article-content{
    font-size: 14px;
  }
}
