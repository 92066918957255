.chatbot-container {
  position: fixed;
  bottom: 122px; /* Adjust position as needed */
  right: 20px; /* Adjust position as needed */
  width: 350px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  z-index: 999; /* Ensure it's above other elements */
}
.email-input-container {
  display: flex;
}

.email-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 5px;
}

.email-input-container button {
  padding: 10px;
  border: none;
  background-color: red;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.new-chat-button {
  cursor: pointer;
}

.new-chat-icon {
  width: 35px;
  height: 35px;
}

.chatbot-input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.chatbot-input input {
  padding: 5px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.chatbot-input button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.chatbot-input button:hover {
  background-color: #0056b3;
}

.chatbot-header {
  background-color: #c31c09;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  border-bottom: 1px solid #c79690;
}

.chatbot-messages {
  padding: 10px;
  max-height: 450px; /* Adjust max height as needed */
  overflow-y: auto;
}

.chatbot-message {
  background-color: #efeeee;
  color: #242323;
  padding: 15px;
  margin-bottom: 8px;
  border-radius: 0px 31px 35px;
  max-width: 70%; /* Adjust max width as needed */
}

/* Add these styles to your existing CSS */
.user-message {
  background-color: #c31c09;
  color: #fff;
  padding: 15px;
  border-radius: 31px 0px 35px 35px;
  justify-self: end;

}

.minimize-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin-right: 10px;
}


.chatbot-options {
  padding: 10px;
  margin-bottom: 25px;
}

.chatbot-option {
  margin-bottom: 8px;
}

.chatbot-option button {
  background-color: #c31c09;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 35px 0px 35px 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatbot-option button:hover {
  background-color: #0056b3;
}
