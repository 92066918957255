 
body {
    margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
        background-color: #fff; /* Replace with your actual color scheme   #fff;*/
        color: white;
        overflow-x: hidden; /* Prevents horizontal overflow */
      }
    
      /* Header Styles */
     /* navbar Css satrt */
.navbar {
  display: block;
  top: 0;
  width: 100%;
  transition: background-color 0.3s ease;
  z-index: 1000;
  position: absolute;
  padding: 0;
}
.navbar-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 10px 20px;
}

.sticky {
  background-color: #101875;
  position: fixed;
  top: 0;
  /* width: 100%; */
  width: 100vw;
  z-index: 1000;
}

.navbar-logo img {
  /* margin-left: 10px; */
  max-width: 100%;
  height: auto;
  width: 70px;
}

 /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.logo-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: white;
  letter-spacing: -1px;
}

.logo-tagline {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: white;
  letter-spacing: -0.5px;
}  */

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;400&display=swap');

 .logo-title {
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Helvetica Narrow","Arial Narrow",Tahoma,Arial,Helvetica,sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: white;
  white-space: nowrap;
  letter-spacing: -1.2px;
}

.logo-tagline {
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Helvetica Narrow","Arial Narrow",Tahoma,Arial,Helvetica,sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: white;
  align-self: center;
  /* align-content: center; */
  /* align-items: center; */
  /* display: ruby-text; */
  letter-spacing: -0.6px;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  transition: transform 0.3s ease;
  align-items: center;

}

.navbar-links li {
  /* padding: 0 16px; */
}

.navbar-links li .menu-item-link{
  padding: 12px 20px;
}
.navbar-links li .menu-item-link .lucide-chevron-down{
  transition: .2s ease-in-out;
}

.navbar-links li .menu-item-link:hover{
  font-weight: 500;
  border-radius: 4px;
  padding: 12px 20px;
  background-color: #FFF;
  opacity: 0.8;
  color: #101875;
}

.navbar-links a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  display: block;
  height: 2px;
  background-color: white;
  margin: 5px 0;
  width: 25px;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }

  .navbar-logo {
    flex-basis: 50%;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-links li {
    text-align: center;
    width: 100%;
  }

  .navbar-links li a {
    padding: 0.5rem 0;
    display: block;
  }
}

.mega-menu {
  display: none;
  position: absolute;
  padding: 15px 0px;
  margin-top: 10px;
  border-radius: 0px 0px 8px 8px;
  background: rgba(3, 7, 37, 0.9);
  backdrop-filter: blur(4px);
  z-index: 100;
  width: 80%;
  max-width: 1160px;
  /* margin-top: 5px; */
  left: 50%;
  transform: translateX(-50%);
}
.mega-sub-menu {
  display: none;
  position: absolute;
  padding: 10px;
  margin-top: 7px;
  border-radius: 0px 0px 8px 8px;
  background: rgba(3, 7, 37, 0.9);
  backdrop-filter: blur(4px);
  z-index: 100;
  width: 150%;
  right: 0;
  max-width: 1160px;
  left: auto;
  text-align: center;
}

.mega-menu-row {
  display: flex;
  /* justify-content: space-between; */
  padding: 0px 15px;
}

/* Base styles for mega-menu-column */
.mega-menu-column {
  width: 33%;
  margin: 15px;
  padding: 0px;
  display: flex;
  border-radius: 10px;
  background-color: #ffffff20;
  /* align-items: center; */
  transition: transform 0.3s ease;
  perspective: 1000px; /* Perspective for 3D effect */
}

.mega-menu-column-nil {
  width: 33%;
  margin: 15px;
  padding: 0px;
  display: flex;
  /* border-radius: 10px; */
  /* background-color: #ffffff40; */
  align-items: center;
  transition: transform 0.3s ease;
  perspective: 1000px; /* Perspective for 3D effect */
}

/* Hover effect */
.mega-menu-column:hover {
  transform: scale(1.1) rotateY(5deg); /* Scale and rotate on Y-axis */
}

.learn-more {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  display: inline-block;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.learn-more:hover {
  text-decoration: underline;
}

/* Styles for the content within the mega-menu-column */
.mega-menu-icon img {
  max-width: 100%;
  height: auto;
}

.mega-menu-icon-platform {
  flex-shrink: 0; /* Do not allow the icon to shrink */
  /* Set a fixed width and height if necessary, for example: */
  width: 100px; /* Set the width of the icon */
  height: 55px; /* Set the height of the icon */
  align-self: flex-start;
  margin: 10px 10px 0px 10px;
  display: flex;
  align-items: center;
}

.mega-menu-icon-service {
  flex-shrink: 0; /* Do not allow the icon to shrink */
  /* Set a fixed width and height if necessary, for example: */
  width: 70px; /* Set the width of the icon */
  height: 65px; /* Set the height of the icon */
  align-self: flex-start;
  margin: 5px 10px 0px 15px;
  display: flex;
  align-items: center;
}

.mega-menu-content {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align items to the start (left) */
  text-align: left; /* Align the text to the left */
  flex-grow: 1; /* Allow the text content to take up remaining space */
  padding-left: 15px;
  padding-right: 15px;
  /* height: 150px; Set a fixed height for consistency */

}

.mega-menu-heading {
  /* Add your styles for headings here */
  margin: 0; /* Removes the default margin */
  color: #0075ff;
  /* font-family: 'Open Sans'; */
  font-size: 1.1em;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}

.mega-menu-paragraph {
  /* Add your styles for paragraphs here */
  margin: 10px 0; /* Adds space above and below the paragraph */
  color: #FFFFFF;
  opacity: 0.90;
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.mega-menu .mega-menu-column .learn-more {
  /* margin-top: 10px !important; */
  color: #ec1313 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 130% !important; /* 15.6px */
  display: inline-block !important; /* Adjust as needed */
}

.show-mega-menu {
  display: block;
}

/* .noHover { */
  /* pointer-events: none; */
  /* display: none; */
/* } */

/* .menu-item:hover .mega-menu {
  display: block; 
} */

/* .mega-menu-column:hover .mega-menu {
   display: none;
} */

/* Mega Menu Responsive Styles for mobile */
@media (max-width: 768px) {
  .mega-menu {
    display: none; /* Hide mega menu on mobile */
  }

  .mobile-menu-popup {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: flex-start; /* Align to the top */
    align-items: flex-start;
  }

  .mobile-menu-popup.open {
    display: flex;
  }

  .popup-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
  }

  .popup-content a {
    display: block;
    margin: 10px 0;
    text-decoration: none;
    color: #0075ff; /* Adjust the color to match your design */
  }
}

/* Ensure mobile menu is hidden on desktop */
@media (min-width: 769px) {
  .mobile-menu-popup {
    display: none;
  }
}

/* Navbar css end */
    
    /* Hero Section Styles */
    .hero::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #101875; /* The color for the overlay */
      opacity: 0.5; /* Adjust the opacity to your liking */
      z-index: 0; /* Ensures the overlay is above the video but below the content */
    }
    
    /* Ensure .hero-content and .hero-image are above the overlay */
    .hero-content, .hero-image {
      position: relative;
      z-index: 2;
    }
    
    /* Adjust video z-index to ensure it's below the overlay */
    .hero video {
      /* ... other styles ... */
      z-index: -1;
    }
      
    .hero-container {
      position: relative;
      overflow: hidden;
    }
    
    .background-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
    }
    
    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5); /* Overlay color with adjustable opacity */
      z-index: -1;
    }
    
    .hero {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 120px 0px 40px 40px;
      z-index: 1;
    }
    
    .hero-content {
      max-width: 38%;
    }
    
    .hero-content h1 {
      font-size: 4.0em;
      /* font-size: 40px; */
      line-height: 115%;
      font-weight: bold;
      margin-bottom: 0.5em;
    }
    
    .hero-content p {
      font-size: 1.5em;
      margin-bottom: 1em;
    }
    
    .button {
      /* font-weight: 600; */
      font-size: 1.2em;
      padding: 10px 20px;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 5px;
      min-width: 150px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      border-style: solid;
      border-width: 0px;
    }

    .navbar-links li .menu-item-link:hover .lucide-chevron-down{
      rotate: 180deg;
      transition: .3s ease-in-out;
    }

    .bg-blue{
      background-color: #2563eb !important;
    }
    
    .button-primary {
      border-radius: 8px;
      background: #ED2411;
      /* box-shadow: 0px 2px 16px 0px rgba(237, 36, 17, 0.50); */
      color: white;
      /* margin-bottom: 20px; */
    }

    .use-case-btn {
      padding: 10px 20px;
      border-radius: 8px;
      background: #ED2411;
      /* box-shadow: 0px 2px 16px 0px rgba(237, 36, 17, 0.50); */
      text-decoration: none;
      display: inline-block;
      /* margin-top: 1em; */
      font-size: 1.2em;
      color: #fff;
      font-weight: 500;
      /* border: 0px 2px 16px 0px rgba(213, 38, 22, 0.5); */
      border-style: solid;
      border-width: 0px;
    }

    .button-primary:hover,
    .use-case-btn:hover{
      /* color: black; */
      /* opacity: .80; */

      background: #b00000;
      /* box-shadow: 1px 3px 18px 1px rgba(241, 20, 0, 0.926); */
      color: white;
      /* font-weight: 700; */
    }

    .button-primary:active,
    .use-case-btn:active{
      background: #b00000;
      /* box-shadow: 1px 3px 18px 1px rgba(241, 20, 0, 0.926); */
      color: white;
      /* font-weight: 700; */
    }

    .button-secondary:hover {
      /* color: #3968f5; */
      opacity: .70;
      /* font-weight: 700; */
      /* box-shadow: 0px 2px 12px 0px rgba(63, 75, 255, 0.40); */
      color: #5A81FA;
    }
    
    .button-secondary {
      border-radius: 8px;
      border: 1px solid #5A81FA;
      box-shadow: 1px 3px 15px 1px rgba(45, 59, 252, 0.651);
      color: #5A81FA;
      background-color: #FFFFFF;
      font-weight: 500;
      opacity: .90;
      position: relative; /* Position relative to the pseudo-element */
      padding-left: 50px; /* Make space for the icon */
      text-align: left; /* Align the text to the left to accommodate the icon on the left */
      display: inline-flex; /* Use flexbox for alignment */
      align-items: center; /* Align items vertically */
      justify-content: center; /* Center button text */
    }

    .button-secondary::before {
      content: '';
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px; /* Ensure the width matches the height for a square icon */
      height: 25px;
      background-image: url('/public/img/play_circle.png'); 
      background-size: cover;
      background-repeat: no-repeat;
    }

    .hero-image {
      max-width: 62%;
      z-index: 1;
    }
    
    .hero-image img {
      width: 100%;
      height: auto; /* Ensures the image keeps its aspect ratio */
    }
    
    @media (max-width: 992px) {
      .hero {
        padding: 60px 75px;
      }
      .hero-content, .hero-image {
        max-width: 100%;
      }
    }
    
    @media (max-width: 768px) {
      .hero {
        flex-direction: column;
        padding: 120px 30px 0px 30px;
        text-align: center;
        margin-top: 0; /* Remove negative margin on mobile */
      }
    
      .hero-content h1 {
        font-size: 3.0em; /* Decrease font size for heading */
        line-height: 110%;
      }
    
      .hero-content p {
        font-size: 1em; /* Decrease font size for paragraph */
      }
    
      .hero-content .button-container,
      .product-hero-content .button-container
      {
        /* display: flex; Use flexbox for button layout */
        justify-content: center; /* Center buttons in the container */
        gap: 16px; /* Add gap between buttons */
        display: inline-grid;
      }
    
      .hero-content .button {
        /* flex: 1;  */
        /* Buttons share the available space */
        /* padding: 12px 10px;  */
        /* Adjust padding for smaller screens */
        margin-bottom: 0; /* Remove bottom margin if any */
        width: auto; /* Override width settings if necessary */
        align-content: center;
      }
    
      .hero-content .button-secondary::before {
        /* Adjustments for mobile, considering smaller button sizes */
        width: 20px; /* Smaller icon for mobile */
        height: 20px; /* Smaller icon for mobile */
        /* left: 5px;  */
        /* Adjust icon position for smaller buttons */
      }
    
    
      .hero-content {
        max-width: none;
      }
    
      .hero-image {
        width: 100%; /* Increase size of image */
      }
    
      .hero-image img {
        max-width: 100%; /* Increase size of image */
        margin-top: 20px; /* Space between text and image */
      }
    }
    
    
    
     /* .section-achievements {
      background-color: white; 
      background-image: url('path/to/your/achievement-background.jpg'); 
      background-size: cover; 
      background-position: center; 
      background-repeat: no-repeat; 
      background-attachment: fixed; 
      padding: 20px 0;
      text-align: center;
      overflow: hidden; 
    } */

    .section-achievements {
      margin-top: 20px;
      margin-bottom: 20px;
      margin: 10px 0;

      .stat-container {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        width: 100%;

        .stat-content{
          display: inline-grid;
          justify-content: left;
          padding: 10px 0;
          width: 100%;
        }
  
        img{
          height: 80px;
          width: 100px;
        }
  
        .big-number {
          color: #5A81FA; /* Example color for numbers (blue) */
          font-size: 2.5em; /* Big numbers for emphasis */
          font-weight: bold;
          /* margin-bottom: 5px; */
          display: flex;
          /* justify-content: center; */
          /* margin-left: 30px; */
        }
        
        .stat-title {
          color: #2B318A; /* Matching color for titles */
          font-size: 1em;
          font-weight: bold;
          /* margin-left: 20px; */
        }
      }

       /* Further adjustments for Mobile Phones */
       @media (max-width: 600px) {
        .stat-container {
          display: block;
          padding-left: 28%; /* Align text and children (like buttons) to the left */
        }
      }
    }

    
    
    /* Adjustments for mobile devices to have two columns */
    /* @media (max-width: 768px) {
      .section-achievements {
        background-attachment: scroll;
      }
    } */
    
    .section-report {
      background: url('/public/free-report.png') no-repeat center center / cover;
      background-attachment: fixed; 
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      color: white;
      padding: 40px 20px;
    }

    .report-content{
      max-width: 40%;
    }

    .report-image-wrapper{
      max-width: 60%;
    }
    
    .report-content,
    .report-image-wrapper {
      /* flex: 1; */
      padding: 20px;
    }
    
    .report-image-wrapper {
      text-align: center; /* Center the image in its column */

      /* Container for the images */
      .image-container {
        position: relative;
        /* display: inline-block; */
        overflow: hidden; /* Ensure the popup doesn't overflow the container */
      }

      /* The image that will be hovered over */
      .image-container .hover-image {
        /* width: 100px; */
        /* height: 100px; */
        z-index: 1; /* Ensure the main image is above the popup image initially */
        /* position: absolute; Ensure it stays in the container */
      }

      /* The popup image */
      .image-container .popup-image {
        position: absolute;
        top: 12%;
        left: 50%;
        width: 18%;
        height: auto;
        z-index: 0; /* Behind the main image initially */
        opacity: 0; /* Initially invisible */
        transform: scale(0.5) translate(30%, 30%);
        /* animation: popupOutAnimation 3s forwards ease-in-out; Animation starts automatically */
      }

      .popup-image.animate{
        animation: popupOutAnimation 3s forwards ease-in-out; /* Animation starts automatically */
      }
    }



    /* Define the animation */
    @keyframes popupOutAnimation {
      0% {
        opacity: 0;
        transform: scale(0.5) translate(15%, 15%);
      }
      100% {
        opacity: 1;
        transform: scale(1.5) translate(15%, 15%);
      }
    }
    
    .report-image {
      max-width: 100%;
      height: auto;
    }
    
    .report-title {
      font-size: 4.0em;
      font-weight: bold;
      margin-bottom: 0.5em;
    }
    
    .report-subtitle {
      font-size: 1.5em;
      margin-bottom: 1em;
    }
    
    .report-button {
      border-radius: 8px;
      text-decoration: none;
      background: #ED2411;
      box-shadow: 0px 2px 16px 0px rgba(237, 36, 17, 0.50);
      color: white;
      padding: 10px 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    /* Responsive adjustments for smaller screens */
    @media (max-width: 768px) {
      .section-report {
        flex-direction: column; /* Stack the columns on small screens */
      }
    
      .report-title {
        font-size: 3em;
      }
    
      .report-subtitle {
        font-size: 1em;
      }

      .report-content{
        max-width: 100%;
      }

      .report-image-wrapper{
        max-width: 100%;
      }
    }
    @media (max-width: 768px) {

      .section-report {
        background-attachment: scroll; /* Disable fixed background on mobile */
      }

      .report-content{
        max-width: 100%;
      }

      .report-image-wrapper{
        max-width: 100%;
      }
    }
      body {
        font-family: 'Open Sans', sans-serif;
    }

    .product-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 40px 20px; /* More padding on larger screens */
        font-family: 'Open Sans', sans-serif;
      }
    
      .product-title {
        font-size: 3em; /* Larger text on larger screens */
        font-weight: bold;
        margin-bottom: 0.5em; /* Adjust the space as needed */
        color: #2B318A; /* Dark grey, assuming it's similar to the screenshot */
      }
    
      .product-description {
        font-size: 1.5em; /* Readable text size on larger screens */
        line-height: 150%;
        color: #63637d; /* Dark grey, assuming it's similar to the screenshot */
        max-width: 80%; /* Limit the line length for readability */
        margin: 0 auto; /* Center the text block if it's less than max-width */
      }
    
      /* Responsive adjustments */
      @media (max-width: 768px) {
        .product-section {
          padding: 20px 10px; /* Less padding on smaller screens */
        }
      }

    .tabs-container {
        /* display: ruby-text; */
        justify-content: center;
        padding: 10px 0px;
        display: flex;
        /* flex-wrap: wrap; */
    }
    
    .tabs {
      padding-left: 0px;
      list-style: none;
      gap: 16px;
      /* padding: 0 10px; */
      margin: 0;
      /* background: #F3F6FF; */
      /* border-radius: 20px; */
      display: grid;
      overflow: hidden;
      justify-content: center;
      grid-template-columns: repeat(5, 1fr);
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
      /* white-space: nowrap; */
    }

      /* Further adjustments for Mobile Phones */
      @media (max-width: 600px) {
        .tabs {
          grid-template-columns: repeat(3, 1fr);
          justify-items: center;

          & > li:nth-child(4),
          & > li:nth-child(5) {
            grid-column: span 1;
            justify-self: center;
          }

        }
      }
    
    .tabs li {
        background: #E6ECFF;
        color: #666;
        min-width: 80px;
        border-radius: 20px;
        text-align: center;
        padding: 10px 20px;
        margin: 4px;
        transition: background-color 0.3s, color 0.3s;
        cursor: pointer;
        font-size: 1.2em;
    }
    
    .tabs li:hover {
        background: #d0d0d0;
        font-weight: 600;
    }
    
    .tabs li.active {
        background: #5A81FA;
        color: #fff;
        font-weight: 600;
    }
    
    .content {
        display: none;
        background: #fff;
        padding: 20px;
        border-top: none;
    }
    
    .content.active {
        display: flex !important;
        justify-content: center;
        align-items: stretch;
        text-align: left;
        gap: 20px;
        max-width: 1200px;
        margin: auto;
    }
    
    .content img {
        width: 50%;
        max-width: 100%; /* Full width on mobile */
        max-height: 300px; /* Fixed max height for image */
        object-fit: contain; /* Show the full image without cropping */
    }

    .content .info-card {
        width: 50%;
        display: contents;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    .content .info-card ul{
      list-style-type: disclosure-closed;
      /* list-style: circle; */
      color: #5a81fa;
    }

    .content .info-card li{
      font-size: calc(1.275rem + .3vw);
    }
    
    @media (max-width: 768px) {
        .content.active {
            flex-direction: column;
            max-width: 100%;
        }
    
        .content img,
        .content .info-card {
            width: 100%;
            object-fit: scale-down;
        }
    
        .content .info-card {
            padding: 0;
        }
    }
    
    .info-card {
        display: contents;
        background: #fff;
        margin: 20px auto;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
    }
    
    .info-card h2 {
        color: #2b318a; /* Dark grey color for the heading */
        margin-bottom: 10px;
    }
    
    .info-card h4 {
        color: #5a81fa; /* Blue color for subheadings */
        margin-bottom: 10px;
    }
    
    .info-card p {
        color: #9494ae; /* Light grey color for the paragraph text */
        line-height: 1.6;
        margin-bottom: 10px;
    }
    
    .services-header {
      text-align: center;
      margin: 40px 0;
    }
    
    .services-header h2 {
      margin: 0;
      font-size: 2em;
      color: #333;
    }

    .service {
      background-color: #f4f5ff;
      margin-top: 40px;
      overflow: hidden;
      /* gap: 20px; */
      /* display: grid; */
      /* padding: 0 20px 40px 20px; */
    }
    
    .service-card {
      background: #ffffff;
      border-radius: 12px;
      padding: 20px 20px;
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      box-shadow: 0px 4px 59.4px 0px rgba(98, 123, 255, 0.12);
      transition: background-color 0.3s, color 0.3s, transform 0.3s;
      overflow: hidden;
    }

    .service-card img{
      height: 100px;
      width: auto;
      margin: -22px 0;
    }

    .service-card-desc {
      gap: 6px;
      display: inline-grid;
    }
    
    /* Hover effects for each card's background, heading, and image icon */
    .card1:hover .card-heading,
    .card1:hover img { 
      color: #FE9706; 
    }
    .card2:hover .card-heading,
    .card2:hover img { 
      color: #02B583; 
    }
    .card3:hover .card-heading,
    .card3:hover img { 
      color: #E10505; 
    }
    .card4:hover .card-heading,
    .card4:hover img { 
      color: #FFD700; 
    }
    .card5:hover .card-heading,
    .card5:hover img { 
      color: #8248FF;
    }
    .card6:hover .card-heading,
    .card6:hover img { 
      color: #5A81FA; 
    }
    
    .card1:hover { border-radius: 12px;
    border: 1px solid rgba(254, 151, 6, 0.10);
    background: linear-gradient(226deg, #FFF 36.78%, #FEF2E2 105.59%);
    }
    .card2:hover { border-radius: 12px;
    border: 1px solid rgba(2, 181, 131, 0.10);
    background: linear-gradient(226deg, #FFF 36.78%, #DEFFF6 105.59%);
    }
    .card3:hover { border-radius: 12px;
    border: 1px solid rgba(217, 0, 0, 0.10);
    background: linear-gradient(226deg, #FFF 36.78%, #FFE8E8 105.59%);
    }
    .card4:hover { border-radius: 12px;
    border: 1px solid rgba(13, 196, 220, 0.10);
    background: linear-gradient(226deg, #FFF 36.78%, #DAFBFF 105.59%);
    }
    .card5:hover { border-radius: 12px;
    border: 1px solid rgba(130, 72, 255, 0.10);
    background: linear-gradient(226deg, #FFF 36.78%, #EEE6FF 105.59%);
    }
    .card6:hover { border-radius: 12px;
    border: 1px solid rgba(90, 129, 250, 0.10);
    background: linear-gradient(226deg, #FFF 36.78%, #E1E8FF 105.59%);
    }
    
    .service-card:hover {
      transform: translateY(-5px); /* Adds a subtle lift effect */
      color: #000; /* Change text color on hover */
    }
    
    .service-card h3, .card-heading {
      /* margin-top: 10px; */
      color: #000; /* Ensures color transitions smoothly */
      font-size: 1.5em;
      line-height: 150%;
      font-weight: 700;
      position: relative;
      align-self: stretch;
      transition: color 0.3s;
      text-align: center;
    }
    
    .paragraph7 {
      color: var(--neutral-colors-text-gray, #9795b5);
      text-align: center;
      font-family: "OpenSans-Regular", sans-serif;
      font-size: 1em;
      line-height: 150%;
      font-weight: 400;
      position:relative;
      width: 100%;
      max-width: 324px;
      margin: 0;
      transition: color 0.3s;
    }
    
    @media (max-width: 1024px) {
      .services-header h2 {
        font-size: 1.75em;
      }
    }
    
    .why-us-container {
      display: flex;
      align-items: center;
      justify-content: space-between; 
      padding: 40px;
      background-image: url('/public/img/about.png');
      background-size: cover; 
      background-position: center;
    }
      
      .why-us-content {
        flex: 1; /* Allocates half of the space to content */
        padding-right: 20px; /* Space between the text and image */
      }
      
      .why-us-image {
        flex: 1; /* Allocates half of the space to the image */
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .why-us-image img {
        width: 100%; /* Full width of its container */
        height: auto;
        max-width: 500px; /* Limits the size to maintain aesthetics */
      }
      
      /* Responsive adjustments for Tablets */
      @media (max-width: 1024px) {
        .why-us-container {
          flex-direction: column; /* Stack elements vertically */
          text-align: center; /* Center the content */
          padding: 20px;
        }
      
        .why-us-content, .why-us-image {
          width: 100%; /* Full width for both text and image */
          padding: 0; /* Remove padding for a cleaner look */
        }
      
        .why-us-content {
          margin-bottom: 20px; /* Adds some space between the text and image */
        }
      }
      
      /* Further adjustments for Mobile Phones */
      @media (max-width: 600px) {
        .why-us-content {
          text-align: left; /* Align text and children (like buttons) to the left */
        }
      
        .why-us-image img {
          max-width: 100%; /* Allows the image to fully scale down */
        }
    
      }
      
       .why-us-title {
        color: var(--x-biz-styles-other-primary-color, #2b318a);
        text-align: left;
        font-family: var(--h2-font-family, "OpenSans-Bold", sans-serif);
        font-size: var(--h2-font-size, 36px);
        line-height: var(--h2-line-height, 120%);
        font-weight: var(--h2-font-weight, 700);
        position: relative;
        align-self: stretch;
        
      }

      .why-us-description {
        color: var(--neutral-colors-text-gray, #9795b5);
        text-align: left;
        font-family: var(--sub-head-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--sub-head-font-size, 18px);
        line-height: var(--sub-head-line-height, 150%);
        font-weight: var(--sub-head-font-weight, 400);
        position: relative;
        align-self: stretch;
        padding-top: 10px;
        padding-bottom: 10px;
      } 
      
    .faq {
        background-color: #f4f5ff;
        padding-top: 50px;
    }
    
    .faq-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .faq-container {
        width: 60%;
        max-width: 700px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        padding: 15px;
        margin-top: 20px;
        margin-bottom: 50px;
        box-sizing: border-box;
    }
    
    .faq-item {
        border-bottom: 1px solid #eee;
        padding: 15px 10px;
        cursor: pointer;
        position: relative;
    }

    .faq-item:last-child {
        border-bottom: none;
    }

    .faq-item::after {
      content: "+";
      position: absolute;
      right: 20px;
      bottom: 10px;
      color: black; 
      font-weight: bold;
      font-size: 1.5em;
    }
    
  .faq-item.active::after {
      content: "-";
  }
    
  .faq-question {
      color: #5A81FA;
      font-size: 1em;
      margin: 0;
      margin-right: 20px;
  }
    
  .faq-answer {
      display: none;
      color: #9795b5;
      padding-top: 10px;
  }
    
  .faq-item.active .faq-answer {
      display: block;
  }
  
  .faq-answer p {
      margin: 0;
  }
    
  @media (max-width: 768px) {
      .faq-container {
          width: 100%;
          padding: 15px;
      }
  }
    
  .container {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 100px 0px 100px; /* Uniform padding */
  }
      
  .image-column,
  .form-column {
    flex: 1; /* Both columns will grow equally */
    max-width: 50%;
    box-sizing: border-box;
    background-color: #F0F2F5;
    border-radius: 5px;
    display: flex; /* Added to use flexbox */
    flex-direction: column; /* Stacks child elements vertically */
    justify-content: flex-end; /* Aligns children (image) at the bottom */
  }
      
      /* On smaller screens, let the columns stack on top of each other */
      @media (max-width: 900px) {
        .image-column,
        .form-column {
          flex-basis: 100%; /* Takes full width */
          max-width: 100%; /* Adjusts max width to 100% */
          /* Adds space between the stacked columns */
        }
      }
      
      .form-container {
        max-width: 100%;
        margin: 0;
        padding: 20px 0px 0px 0px; /* Reduced padding on the right */
        border-radius: 8px;
      }
      
      .form-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      
      .form-group.double .input-container {
        flex-basis: calc(50% - 10px); /* Adjust the spacing based on your layout */
        margin-right: 0px;
      }
      
      .form-group.double .input-container:last-child {
        margin-right: 0px; /* Ensure there's no margin on the right for the last input container */
      }
      
      .form-group label {
        margin-bottom: 5px;
        color: #63637D;
      }
      
      .form-group input[type="text"],
      .form-group input[type="email"],
      .form-group input[type="tel"],
      .form-group textarea {
        width: 100%;
        padding: 10px;
        border: 0px solid #727b9a00;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
      }
      
      .form-group textarea {
        height: 80px;
      }
      
      .form-group input[type="submit"] {
        /* width: auto;
        padding: 10px;
        margin-top: 15px;
        font-size: 1.2em;
        border: none;
        border-radius: 8px;
        background: #ED2411;
        box-shadow: 0px 2px 16px 0px rgba(237, 36, 17, 0.50);
        color: white; */
        cursor: pointer;
      }
      
      /* Adjustments for medium screens */
      @media (max-width: 767px) {
        .form-container {
          padding: 40px 20px; /* Reduced side padding */
        }
      
        .form-group.double .input-container {
          flex-basis: 100%;
          margin-right: 0;
        }
      }
      
      /* Adjustments for very small screens */
      @media (max-width: 480px) {
        .container {
          padding: 50px 20px 0px 20px; /* Reduced padding for smaller screens */
        }
      
        .form-container {
          padding: 20px; /* Even smaller padding */
        }
      
        .form-group {
          margin-bottom: 10px; /* Less space between form groups */
          gap: 10px;
        }
      }

      .contact-section {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        text-align: left;
        padding: 30px 20px; /* More padding on larger screens */
        font-family: 'Open Sans', sans-serif;
      }
    
      .contact-title {
        font-size: 3em; /* Larger text on larger screens */
        font-weight: bold;
        margin-bottom: 0.5em; /* Adjust the space as needed */
        color: #2B318A; /* Dark grey, assuming it's similar to the screenshot */
        /* padding-left: 20px; */
      }
    
      .contact-description {
        font-size: 1.5em; /* Readable text size on larger screens */
        line-height: 150%;
        color: #63637d; /* Dark grey, assuming it's similar to the screenshot */
         /* Limit the line length for readability */
        margin: 0 auto; /* Center the text block if it's less than max-width */
        /* padding-left: 20px; */
        align-items: center;
      }

      .contact-description.eb {
        font-size: 1.5em; /* Readable text size on larger screens */
        line-height: 150%;
        color: #63637d; /* Dark grey, assuming it's similar to the screenshot */
         /* Limit the line length for readability */
        margin: 0 auto; /* Center the text block if it's less than max-width */
        padding-left: 20px;
        display:flex;
        justify-content: center;
      }
    
      /* Responsive adjustments */
      @media (max-width: 768px) {
        .contact-section {
          padding: 20px 10px; /* Less padding on smaller screens */
        }
    
        .contact-title {
          font-size: 1.5em; /* Slightly smaller text on smaller screens */
        }
    
        .contact-description {
          font-size: 0.9em; /* Slightly smaller text on smaller screens */
        }

        .contact-description-container{
          margin-top: 20px;
          margin-left: -17px;
        }
      }
    
    .back-to-top {
      position: relative; /* Keeps the original relative positioning */
      left: 450px; /* Initial left positioning, may need adjustment based on the parent container */
      bottom: 60px; /* Pushes the button up from its normal position */
      /* background-color: #ED2411; */
      /* color: #fff; */
      /* padding: 10px 20px; */
      border-radius: 0px 0px 10px 10px !important;
      /* text-decoration: none; */
      /* font-family: 'Open Sans', sans-serif; */
      /* font-weight: bold; */
      /* transition: background-color 0.3s, color 0.3s; */
      cursor: pointer;
      /* overflow: hidden; */
      z-index: 999;
    }
    
    .back-to-top:hover {
        /* background-color: #C51E10;  */
        /* Darker shade for hover effect */
        /* color: white; */
    }
    
    /* Responsive adjustments */
    /* Adjustments for screens smaller than 900px */
    @media (max-width: 900px) {
      .back-to-top {
          left: 50%; /* Center horizontally */
          transform: translateX(-50%); /* Adjust to precisely center the button */
          bottom: 10px; /* Less displacement from the bottom */
      }
    }
    
    /* Adjustments for screens smaller than 768px (common tablet breakpoint) */
    @media (max-width: 768px) {
      .back-to-top {
          left: 50%; /* Center horizontally */
          transform: translateX(-50%); /* Keeps the button centered */
          bottom: 60px; /* Slightly more room at the bottom */
          padding: 8px 16px; /* Smaller padding */
      }
    }
    
    /* Adjustments for screens smaller than 468px (smaller mobile devices) */
    @media (max-width: 468px) {
      .back-to-top {
          left: 50%; /* Center horizontally */
          transform: translateX(-50%); /* Center the button horizontally */
          bottom: 60px; /* Closer to the bottom to save space */
          padding: 5px 10px; /* Even smaller padding for very small screens */
      }
    }
    
      
      /* Reset any default margins and padding that could interfere */
    /* Full width footer */
    .site-footer {
      background-image: url('/public/img/footer.png');
      background-repeat: no-repeat; 
      background-size: cover; 
      background-position: center center; 
      color: white;
      font-family: 'Open Sans', sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 20px; 
    }
    
    .footer-content {
      display: flex;
      flex-direction: column; /* Stack children vertically on mobile */
      align-items: center; /* Center-align children */
      width: 100%;
      flex-wrap: wrap;
    }
    
    .footer-logo-address, .footer-links {
      flex-basis: 100%; /* Full width on mobile */
      text-align: center;
      /* padding-bottom: 10px; */
      padding-top: 10px;
    }
    
    .footer-logo {
      max-width: 200px; /* Adjust logo size */
      padding-bottom: 20px;
    }
    
    .social-icons a {
      margin: 0 10px; /* Adjust spacing around social icons */
    }
    
    .footer-links {
      margin-bottom: 20px;
    }
    
    .footer-links h4 {
      margin-bottom: 10px;
    }
    
    .footer-links ul {
      list-style-type: none;
      padding: 0;
    }
    
    .footer-links ul li a {
      color: white;
      text-decoration: none;
      transition: color 0.3s;
    }
    
    .footer-links ul li a:hover {
      color: #ffdd00; /* Adjust hover color */
    }
    
    .footer-bottom {
      background-color: #060941; /* Darker shade for footer bottom */
      color: white;
      width: 100%;
      text-align: center;
      padding: 0px; /* Increased padding for mobile */
    }
    
    .footer-bottom-content {
      display: flex;
      flex-direction: column; /* Stack elements vertically */
      align-items: center; /* Center content */
      padding: 10px; /* Adjust padding as needed */
    }
    
    .footer-bottom p {
      margin-bottom: 10px; /* Add spacing between elements */
    }
    
    .footer-bottom-links {
      display: flex;
      flex-direction: column; /* Stack links vertically */
      align-items: center; /* Center links */
    }
    
    .footer-bottom-links a {
      color: white;
      text-decoration: none;
      margin: 5px 0; /* Add spacing between stacked links */
    }
    
    /* Responsive styles for desktop */
    @media (min-width: 768px) {
      .site-footer {
        padding: 60px 50px; /* Increased horizontal padding for desktop */
      }
    
      .footer-content {
        flex-direction: row; /* Horizontal layout for desktop */
        justify-content: space-between; /* Distribute space between items */
        align-items: start;
      }
    
      .footer-logo-address, .footer-links {
        flex-basis: calc(25% - 40px); /* Adjust the width for desktop */
        text-align: left;
      }
    
      .footer-bottom-content {
        flex-direction: row; /* Horizontal layout for desktop */
        justify-content: space-between; /* Distribute space between items */
        padding: 10px 20px;
      }
    
      .footer-bottom-links {
        flex-direction: row; /* Horizontal layout for links */
        margin-top: 0;
      }
    }
    
    #chatbot-container {
      position: fixed;
      bottom: 50px;
      right: 20px;
      display: flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other elements */
      overflow: hidden; /* Prevents overflow of child elements */
    }
    
    .chatbot-Icon{
      margin-left: -3px;
      margin-right: -4px;
    }

    #chatbot-message {
      background: white;
      border-radius: 5px;
      padding: 9px;
      margin-right: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Consistent with the container's shadow */
      overflow: hidden; /* Prevents text from spilling out */
    }
    
    #chatbot-message p {
      margin: 0;
      color: #FF1E00;
      font-size: 0.9em;
      font-weight: 600;
      overflow: hidden; /* Ensures text overflow is managed */
    }
    
    #chatbot-icon {
      background: #FF1E00;
      border-radius: 50%;
      padding: 5px;
      overflow: hidden; /* Keeps the icon contained */
    }
    
    #chatbot-button {
      background: transparent;
      border: none;
      display: flex;
    }
    
    #chatbot-button img {
      width: 30px;
      height: auto;
      overflow: hidden; /* Ensures the image doesn't exceed the button bounds */
    }
    
    /* Responsive adjustments for medium-sized devices */
    @media (max-width: 768px) {
      #chatbot-container {
        bottom: 30px;
        right: 25px;
      }
    
      #chatbot-message {
        padding: 8px;
        margin-right: 8px;
      }
    
      #chatbot-message p {
        font-size: 0.85em; /* Adjust font size */
      }
    
      #chatbot-icon {
        padding: 8px; /* Smaller icon padding */
      }
    
      #chatbot-button img {
        width: 28px; /* Slightly smaller icon size */
      }
    }
    
    /* Further adjustments for very small screens */
    @media (max-width: 480px) {
      #chatbot-container {
        bottom: 20px;
        right: 40px;
      }
    
      #chatbot-message {
        padding: 6px;
        margin-right:26px;
      }
    
      #chatbot-message p {
        font-size: 0.8em; /* Smaller font size for very small screens */
      }
    
      #chatbot-icon {
        padding: 6px; /* Further reduce padding */
      }
    
      #chatbot-button img {
        width: 25px; /* Smaller icon size */
      }
    }
    
    .breach-section {
      min-height: 500px; 
      position: relative;
      color: white;
      text-align: center;
      background-image: url(/public/img/breach.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    
    .breach-content {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
    }
    
    .breach-title {
      font-size: 2em;
      margin-bottom: 0.5em;
      color: #FF1E00; /* Updated text color */
      text-shadow: 2px 2px 6px #FF1E00; /* Drop shadow with specified color and blur */
    }
    
    /* .breach-btn {
      display: inline-block;
      padding: 10px 20px;
      font-size: 1.2em;
      border: none;
      border-radius: 5px;
      background-color: #25D366;
      color: white;
      text-decoration: none;
      transition: background-color 0.3s;
    }
    
    .breach-btn:hover {
      background-color: darkred;
    } */
    
    .breach-btn {
      border-radius: 8px;
      border: 1px solid #5A81FA;
      box-shadow: 1px 3px 15px 1px rgba(45, 59, 252, 0.651);
      color: #5A81FA;
      background-color: #FFFFFF;
      font-weight: 500;
      opacity: .90;
      position: relative; /* Position relative to the pseudo-element */
      /* padding-left: 50px; */
       /* Make space for the icon */
      text-align: left; /* Align the text to the left to accommodate the icon on the left */
      display: inline-flex; /* Use flexbox for alignment */
      align-items: center; /* Align items vertically */
      justify-content: center; /* Center button text */
    }

    .breach-btn:hover {
      opacity: .70;
    }
    
    @media (max-width: 600px) {
      .breach-title {
        font-size: 1.5em;
      }
      .breach-btn {
        padding: 8px 16px;
        font-size: 0.9em;
      }
    }

    .tooltip {
      position: absolute;
      color: #000;
      background-color: white;
      border: 1px solid black;
      /* padding: 5px; */
      z-index: 1000;
      opacity: 1 !important;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      
    }
    
    
    /* Product Page CSS Start */
    
    .product-hero-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 120px 30px 0px 30px;
      text-align: center;
      overflow: hidden;
    }
    
    .product-hero-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #101875; /* The color for the overlay */
      opacity: 0.5; /* Adjust the opacity to your liking */
      z-index: 1; /* Ensures the overlay is above the video but below the content */
    }
    
    .background-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0; /* Make sure this is below the overlay */
    }
    
    .product-hero-content {
      position: relative;
      width: 100%;
      max-width: 600px;
      margin-bottom: 20px;
      z-index: 2; /* Ensure content is above overlay */
    }
    
    .product-hero-content h1 {
      font-size: 50px;
      line-height: 115%;
      font-weight: bold;
      margin-bottom: 0.5em;
      color: #fff; /* Ensure heading color is visible over the overlay */
    }
    
    .product-hero-content h4, .product-hero-content p {
      font-size: 1.5em;
      margin-bottom: 1em;
      color: #C5C5CC;
    }
    
    .product-hero-image {
      display: flex; /* Use flexbox for centering */
      justify-content: center; /* Center the image horizontally */
      width: auto; /* Let the image be the width it needs to be */
      max-width: 500px; /* Set a max-width for larger screens */
      margin-top: 20px;
      z-index: 2; /* Ensure image is above overlay */
    }
    
    .product-hero-image img {
      height: auto;
    }
    
    @media (min-width: 992px) {
      .product-hero-image {
        max-width: 700px; /* Larger size on desktop */
      }
    }
    
    @media (max-width: 992px) {
      .product-hero-section {
        padding: 60px 50px;
      }
      .product-hero-content {
        max-width: none;
      }
    }
    
    @media (max-width: 768px) {
      .product-hero-section {
        padding: 160px 20px 0px 20px;
      }
    
      .product-hero-content h1 {
        font-size: 30px;
      }
    
      .product-hero-image img {
        width: 100%; /* Image will fill the container */
        max-width: 500px; /* Limit image size on larger screens */
        height: auto;
      }
    
      .product-hero-content h4, .product-hero-content p {
        font-size: 1em;
      }
    
      .product-hero-image {
        max-width: 100%; /* Full width on smaller screens */
      }
    }
    .product-stats-section {
      display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1200px;
        padding: 100px 20px 100px 20px;
        gap: 20px;
    }
    
    .product-stats-column {
      flex-basis: calc(33.333% - 20px); /* Subtract the gap from the width */
      padding: 20px;
      box-sizing: border-box; /* Include padding in the width calculation */
    }
    
    .product-stats-column h2{
        color: #2B318A;
        margin-bottom: 10px;
        /* font-family: "Open Sans"; */
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    
    .product-stats-column h3{
      color: #5A81FA;
      margin-bottom: 10px;
      /* font-family: "Open Sans"; */
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 115%; /* 64.4px */ 
    }
    
    .product-stats-column h4{
      color: #5A81FA;
      margin-bottom: 10px;
      /* font-family: "Open Sans"; */
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 21.6px */
    }
    
    .product-stats-column p {
      color: #666666; /* Replace with the actual color */
      margin-bottom: 10px;
    }
    
    .product-stats-box {
      background-color: #ffffff;
      box-shadow: 0px 4px 65.8px 0px rgba(193, 208, 255, 0.40);
      border-radius: 8px; /* Optional rounded corners */
    }
    
    /* Responsive adjustments */
    @media (max-width: 992px) {
      .product-stats-column {
        flex-basis: calc(50% - 20px); /* 2 columns layout for tablets */
      }
    }
    
    @media (max-width: 768px) {
      .product-stats-column {
        flex-basis: 100%; /* Stack columns on smaller screens */
        margin-bottom: 20px; /* Add space between stacked columns */
      }
    
      .product-stats-box {
        box-shadow: 0px 4px 65.8px 0px rgba(193, 208, 255, 0.40); /* Optionally remove box-shadow on smaller screens */
      }
    }
    .product-features-section {
      background: #F0F2F5;
      margin: 0 auto;
      padding: 40px 15px;
      text-align: center;
    }
    
    .product-features-section {
      margin: 0 auto;
      padding: 40px 15px;
      text-align: center;
    }
    
    .product-features-header h2 {
      margin-bottom: 0.5em;
      font-family: "Open Sans", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
      color: #2B318A;
    }
    
    .product-features-header p {
      margin-bottom: 2em;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
      color: #63637D;
    }
    
    .product-features-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    
    .product-features-image {
      flex: 1;
      max-width: 50%;
    }
    
    .product-features-image img {
      width: 100%;
      height: auto;
    }
    
    .product-features-text {
      flex: 1;
      max-width: 50%;
    }
    
    .feature-item {
      display: flex;
      margin-bottom: 1em;
    }
    
    .feature-icon {
      background-image: url('/public/img/tick.png'); 
      background-size: contain; 
      background-repeat: no-repeat;
      display: block; 
      width: 24px; 
      height: 24px; 
      margin-right: 10px; 
    }
    
    .feature-text h3 {
      margin-top: 0;
      margin-bottom: 0.5em;
      /* font-family: "Open Sans"; */
     font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 21.6px */
      text-align: left;
      color: #2B318A;
    }
    
    .feature-text p {
      margin-top: 0;
      /* font-family: "Open Sans"; */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      text-align: left;
      color: #63637D;
    }
    
    /* Responsive Styles */
    @media (max-width: 768px) {
      .product-features-content {
        flex-direction: column;
      }
    
      .product-features-image,
      .product-features-text {
        max-width: 100%;
      }
    
      .feature-item {
        flex-direction: row; /* Keep items in a row */
        align-items: center; /* Align items vertically */
      }
    
      .feature-icon {
        align-self: flex-start; /* Align icon to the start (left) */
        margin-right: 10px; /* Keep space between the icon and the text */
        width: 50px; /* Width of the icon */
        height: 24px; /* Height of the icon */
      }
    
      .feature-text {
        align-self: flex-start; /* Align text to the start (left) */
      }
    
      .feature-text h3,
      .feature-text p {
        text-align: left;
      }
    }
    /* Reverse the order of flex items for the new row */
    
    
    /* Keep the reversed order on mobile as well */
    @media (max-width: 768px) {
      .product-features-content.reverse-row {
        flex-direction: column-reverse;
      }
    }
    .use-case-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 40px 15px;
      background: #ffffff; /* or any other color or image you need */
    }
    
    .use-case-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
    
    .use-case-text {
      flex-basis: 50%;
    }
    
    .use-case-text h2 {
      color: #2B318A; /* your heading color */
      margin-bottom: 1em;
      /* font-family: "Open Sans"; */
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
    }
    
    .use-case-text p {
      color: #555555; /* your paragraph color */
      margin-bottom: 1em;
      font-size: 1.5em;
    }
    
    .use-case-image {
      flex-basis: 50%;
    }
    
    .use-case-image img {
      width: 100%;
      height: auto;
    }
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
      .use-case-content {
        flex-direction: column;
      }
    
      .use-case-text,
      .use-case-image {
        flex-basis: 100%;
      }
    
      .use-case-image {
        margin-top: 20px;
      }
    }
    
    .about-hero-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 120px 30px 0px 30px;
      text-align: center;
      margin-top: -80px; /* Consider removing if not needed to align with your design */
      overflow: hidden;
    }
    
    .about-hero-section::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #101875; /* The color for the overlay */
      opacity: 0.5; /* Adjust the opacity to your liking */
      z-index: 1; /* Ensures the overlay is above the video but below the content */
    }
    
    .background-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0; /* Make sure this is below the overlay */
    }
    
    .about-hero-content {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      padding: 100px 0px 50px 0px;
      z-index: 2; /* Ensure content is above overlay */
    }
    
    .about-hero-content h1 {
      font-size: 50px;
      line-height: 115%;
      font-weight: bold;
      margin-bottom: 0.5em;
      color: #fff; /* Ensure heading color is visible over the overlay */
    }
    
    .about-hero-content h4, .about-hero-content p {
      font-size: 1.5em;
      margin-bottom: 1em;
      color: #C5C5CC;
    }
    
    .about-hero-image {
      display: flex; /* Use flexbox for centering */
      justify-content: center; /* Center the image horizontally */
      width: auto; /* Let the image be the width it needs to be */
      max-width: 500px; /* Set a max-width for larger screens */
      margin-top: 20px;
      z-index: 2; /* Ensure image is above overlay */
    }
    
    .about-hero-image img {
      height: auto;
    }
    
    @media (min-width: 992px) {
      .aboutt-hero-image {
        max-width: 700px; /* Larger size on desktop */
      }
    }
    
    @media (max-width: 992px) {
      .about-hero-section {
        padding: 60px 50px;
      }
      .aboutt-hero-content {
        max-width: none;
      }
    }
    
    @media (max-width: 768px) {
      .about-hero-section {
        padding: 160px 20px 0px 20px;
      }
    
      .about-hero-content h1 {
        font-size: 30px;
      }
    
      .about-hero-image img {
        width: 100%; /* Image will fill the container */
        max-width: 500px; /* Limit image size on larger screens */
        height: auto;
      }
    
      .about-hero-content h4, .about-hero-content p {
        font-size: 1em;
      }
    
      .about-hero-image {
        max-width: 100%; /* Full width on smaller screens */
      }
    }
    .cyber-risk-section {
      background: #FFFFFF;
      padding: 60px 25px 60px 25px;
      text-align: center;
    }
    
    .cyber-section-heading h1{
      color: #2B318A; /* Adjust the color to match your design */
      /* font-family: "Open Sans"; */
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
    }
    .cyber-section-heading h2 {
      color: #2B318A; /* Adjust the color to match your design */
      /* font-family: "Open Sans"; */
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
      margin-bottom: 0.5em;
    }
    
    .cyber-section-heading p {
      color: #63637D; /* Adjust the color to match your design */
      margin-bottom: 2em;
      /* font-family: "Open Sans"; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
    }
    
    .cyber-features-container {
      display: flex;
      justify-content: space-around;
      gap: 20px;
    }
    
    .cyber-feature-item {
      flex-basis: calc(33.333% - 20px); /* Adjust the gap as needed */
      text-align: center;
    }
    
    .cyber-feature-item img {
      width: auto; /* Adjust as needed */
      height: 110px; /* Adjust as needed */
      margin-bottom: 1em;
    }
    
    .cyber-feature-item h3 {
       /* Adjust font-size as needed */
      color: #2B318A; /* Adjust the color to match your design */
      /* font-family: "Open Sans"; */
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
      .cyber-features-container {
        flex-direction: column;
      }
      
      .cyber-feature-item {
        flex-basis: 100%;
      }
    }
    .services-section {
      max-width: 1200px;
      margin: 0 auto;
      padding: 40px 15px;
      background: #fff;
    }
    
    .services-intro h1 {
      color: #2B318A;
      margin-bottom: 0.5em;
      text-align: left;
      /* font-family: "Open Sans"; */
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
    }
    
    .services-intro p {
      text-align: left;
      color: #63637D;
      margin-bottom: 2em;
      /* font-family: "Open Sans"; */
      font-size: 18px;
      font-style: normal;
      text-align: justify;
      font-weight: 400;
      line-height: 150%; /* 27px */
    }
    
    .services-content {
      display: flex;
      gap: 20px;
    }
    
    .services-navigation {
      flex-basis: 30%;
      padding-right: 20px;
      padding-right: 20px;
      background: #fff;
      border-radius: 12px 12px 12px 12px;
    }

    .services-navigation-nil {
      flex-basis: 0%;
      padding-right: 20px;
      padding-right: 20px;
      background: #fff;
      border-radius: 12px 12px 12px 12px;
    }
    
    .services-navigation ul {
      list-style: none;
      padding: 20px 16px 0px 16px;
      color: #63637D;
      text-align: left;
      border: 1px solid;
      border-radius: 20px;
    }
    
    .services-navigation li {
      margin-bottom: 0.5em;
      cursor: pointer;
      /* font-family: "Open Sans"; */
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
      padding: 20px 16px 16px 16px;
      align-items: left;
      gap: 10px;
    }
    
    .services-detail {
      flex-basis: 70%;
      text-align: left;
    }

    .services-detail-only {
      flex-basis: 100%;
      text-align: left;
    }
    
    .services-detail h2 {
      color: #2B318A;
      /* font-family: "Open Sans"; */
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
      margin-bottom: 0.5em;
    }
    
    .services-detail p {
      color: #63637D;
      /* font-family: "Open Sans"; */
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      text-align: justify;
      line-height: 150%; /* 27px */
      margin-bottom: 0.5em;
    }
    
    /* .services-detail img {
      width: 100%;
      height: auto;
      margin: 2em 0;
    } */
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
      
      .services-content {
        flex-direction: column;
      }
    
      .services-navigation,
      .services-detail {
        flex-basis: 100%;
      }
      
      /* .services-detail img {
        margin: 1em 0;
      } */
    }
    .services-subsection {
      display: flex;
      /* align-items: center; */
      gap: 20px;
      margin-top: 2em;
    }
    
    .subsection-image {
      flex: 1;
      max-width: 50%;
    }
    
    .subsection-image img {
      width: 100%;
      height: 320px;
      padding: 20px;
    }
    
    .subsection-text {
      flex: 1;
      max-width: 50%;
      text-align: left;
    }

    .subsection-text h3 {
      color: #5A81FA;
      /* font-family: "Open Sans"; */
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      /* line-height: 46px;  */
      /* 191.667% */
      margin-bottom: 0.3em;
    }

    .subsection-text ul li {
      list-style-type: disclosure-closed;
      font-size: 24px;
      color: #5A81FA;
    }
    
    .subsection-text p {
      color: #9795B5;
      /* font-family: "Open Sans"; */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin-bottom: 1em;
    }
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
      .services-subsection {
        flex-direction: column;
      }
    
      .subsection-image,
      .subsection-text {
        max-width: 100%;
      }
    
      .subsection-text {
        text-align: center;
      }
      
      .subsection-text button {
        margin: 0 auto; /* Center button if subsection-text is centered */
      }
    }    
  
  .section-list{
    list-style-type: disc;
    color: #000;
    padding: 40px;
  }
  
  .p20{
    padding:20px;
  }
  
  .centered {
    position: absolute;
    top: calc(100% + 50px); /* Adjust the value as needed */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .footer-links li {
    padding: 8px;
  }
  
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100vw; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    max-width: 760px; /* Could be more or less, depending on screen size */
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Responsive Video */
  .video-responsive {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
  }
  .video-responsive iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }

  @media (max-width: 768px){
    .button {
      font-size: 1em !important;
      padding: 10px 15px;
    }
  }
