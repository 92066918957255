.nav-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }
  
  .nav-button {
    background-color: white;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
  }
  
  .nav-button:hover {
    border-color: #333;
  }
  
  .nav-button.selected {
    background-color: #0366d6;
    color: white;
    border-color: #0366d6;
  }