.bg-gradient-to-r {
  background-image: linear-gradient(to right, #2563eb, #7c3aed) !important;
}

.max-w-7xl {
  max-width: 80rem !important; /* 1280px */
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.sm\:px-6 {
  @media (min-width: 640px) {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.lg\:px-8 {
  @media (min-width: 1024px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

.flex {
  display: flex !important;
}

.items-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.bg-indigo-800 {
  background-color: #3730a3 !important;
}

.h-6 {
  height: 1.5rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.text-white {
  color: #ffffff !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.font-medium {
  font-weight: 500 !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.bar-content{
  font-size: 16px;
  font-weight: 700;
}

.md\:hidden {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.md\:inline {
  @media (min-width: 768px) {
    display: inline !important;
  }
}

.fade-in {
	-webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@media (max-width: 576px) {
  .bar-content{
    font-size: 13px;
  }
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

