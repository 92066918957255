/* Basic styles for the modal */
.formimg {
  text-align: center;
  margin: auto;
}

.sidebar-modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%z;
  width: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: transform 0.3s ease;
  transform: translateY(-100%);
}


/* .form {
  margin-top: 60px;
} */

.contact-image img {
  width: -webkit-fill-available;
}

.sidebar-modal.active {
  transform: translateY(0);
  top: 0px;
}

.sidebar-modal-content {
  background: #e9ecef;
  padding: 40px;
  border-radius: 8px;
  width: 70rem;
  height: 40rem;
  /* max-width: 400px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}

.captcha-form {
  display: flex;
  align-items: center; /* Align items vertically */
}

.sidebar-modal-close-btn {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.demo-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-fields {
  width: 100%;
}

.form-fields h2 {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.form-fields p {
  font-size: 16px;
  color: #0a5bbf;
  text-align: center;
  margin-bottom: 25px;
  /* margin-top: 30px; */
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* margin-left: 100px; */
}

.shadow-box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
}

textarea.shadow-box {
  width: 100%;
  resize: none;
}

.captcha {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

button[type="submit"] {
  width: 150px;
  padding: 10px;
  background: #5379f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 39px;
}
.form-redius {
  border-radius: 10px;
}

button[type="submit"]:hover {
  background: #3700b3;
}

.forgot-password {
  font-size: 14px;
  color: #6200ea;
  cursor: pointer;
  margin-top: 10px;
}

.forgot-password:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .sidebar-modal {
    top: -300px;
  }

  .sidebar-modal-content {
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .form-fields h2 {
    margin: 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .form-fields p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .demo-container {
    margin-top: 0px;
  }
  .form {
    margin-top: 20px;
  }

  .contact-image img {
    height:100px;
    width: 100px;
  }
}

@media only screen and (max-width: 850px) {

  .form-fields h2 {
    margin-bottom: 0px;
  }

  .form-fields p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .demo-container {
    margin-top: 0px;
  }
  .form {
    margin-top: 20px;
  }
}
